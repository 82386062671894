 
import './duyum.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import skur from './../img/skur.jpg';
  import sgov from './../img/sgov.jpg';
  import smag from './../img/smag.jpg';
 
  

function Duyum() {
      return (
        <div id="duyum" className="duyum_container">
          
            <div className='duyum'>
              <div className='card_flex'>


<div className='card'>
<div className='card_img'>
<img 
           src={skur}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Сэндвич с курицей</b> </div>
<div className='card_comment'>стандарт</div>
<div className='card_footer'>
<div className='card_price'>170 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>



<div className='card'>
<div className='card_img'>
<img 
           src={sgov}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Сэндвич с говядиной</b> </div>
<div className='card_comment'>котлет, сыр чедер </div>
<div className='card_footer'>
<div className='card_price'>190 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>


<div className='card'>
<div className='card_img'>
<img 
           src={smag}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Сэндвич маг</b> </div>
<div className='card_comment'>новинка + </div>
<div className='card_footer'>
<div className='card_price'>250 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>








            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Duyum;