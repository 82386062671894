 
import './shaurma.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import sh from './../img/sh.jpg';
  import shc from './../img/shc.jpg';
  import sht from './../img/sht.jpg';
  import shsh from './../img/shsh.jpg';

function Shaurma() {
      return (
        <div id="shaurma" className="shaurma_container">
          
            <div className='shaurma'>
              <div className='card_flex'>


<div className='card'>
<div className='card_img'>
<img 
           src={shc}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Шаурма в сырном лаваше</b> </div>
<div className='card_comment'>мясо, овощи, фирменный соус, лук маринованный</div>
<div className='card_footer'>
<div className='card_price'>220 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>



<div className='card'>
<div className='card_img'>
<img 
           src={shsh}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Шаурма в шпинатном лаваше</b> </div>
<div className='card_comment'>мясо, овощи, фирменный соус, лук маринованный   </div>
<div className='card_footer'>
<div className='card_price'>220 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>

<div className='card'>
<div className='card_img'>
<img 
           src={sht}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Шаурма в томатном лаваше</b> </div>
<div className='card_comment'>мясо, овощи, фирменный соус, лук маринованный </div>
<div className='card_footer'>
<div className='card_price'>220 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>


<div className='card'>
<div className='card_img'>
<img 
           src={sh}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Шаурма классическая</b> </div>
<div className='card_comment'>мясо, овощи, фирменный соус, лук маринованный </div>
<div className='card_footer'>
<div className='card_price'>210 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>





            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Shaurma;