 
import './send.css';
import shef from './../img/shef.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import { Mail } from '../Pages/Mail';
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
 
  import 'react-circular-progressbar/dist/styles.css';
  import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

  import { ProgressBar } from 'react-bootstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
 
  import axios from 'axios';
  

function Send() {
 
 
      return (
        <div className="send_container">
          <div className='send_grid'>

          <div data-aos="fade-up" class="zakaz">
  <div class="zakazchik">
  <div className="menu_shef">
  <p> У нас вы найдете богатый выбор разнообразных еды быстрого приготовления, 
    которые идеально подходят для того, 
    чтобы разделить их с друзьями и насладиться ими в нашем уютной обстановке. </p>
    
 

 
 


 </div>
    <div class="shef">
 
       <p>Добро пожаловать к нам,

     <br/>у нас вкусно и не дорого</p> 
     <img 
           src={shef}
           alt="shef"
           width="100%"
           height="100%"
                      />  
 
   

    </div>
    
 
  
  </div>
</div>


          </div>
               </div>
              
           
           
        

       
   )
}

 
export default Send;