 
import './hurish.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
 
   
 
 
  

function Hurish() {
 
       return (
        <div id="hurish" className="hurish_container">
          
            <div  className='hurish'>
              <div className='card_flex'>
                  
                  
               

                 





            </div>
          </div>
              
           
       
        

        </div>
   )
}

 
export default Hurish;