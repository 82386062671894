import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './../img/log_1.png';
 
 
 
 
 
 
 
import settings from './../img/settings.png';
 
import send from './../img/22.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons';
 
import korzina3 from './../img/Korzina3.png';
import korzina2 from './../img/Korzina2.png';
import './Header.css';
import React, { useEffect, useState } from 'react'
  
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, 
  FaMailBulk, FaUser, FaUsers, FaUserCircle, FaUserPlus, FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, 
  FaStethoscope, FaCogs, FaUserSecret, FaSearch, FaUniversity, FaSchool, FaSitemap, FaUserTie, FaAngleRight, FaCalendar, FaCalendarCheck, FaCalendarDay, FaCalendarWeek, 
  FaFile, FaFileAlt, FaFileArchive, FaFileCode, FaFileCsv, FaFilePdf, FaFileImport, FaFileWord, FaFileContract, FaFileDownload, 
  FaSistrix, FaSignInAlt, FaCog, FaHome, FaDatabase, FaPhotoVideo, FaAddressBook, FaTable, FaMailchimp, FaAngleDown, FaSign, FaUpload, FaGlassMartini, FaUtensils, FaBirthdayCake, FaCircle, FaAlignLeft, FaAngleLeft, FaTeamspeak} from 'react-icons/fa';
  import Aos from 'aos';
  import 'aos/dist/aos.css';
  import Basket from '../Components/Basket';
import { BrowserRouter as Router, Switch, Route, Link}  from 'react-router-dom';
 
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
 
import { useRef } from "react";
import {FormGroup, FormLabel, FormControl} from 'react-bootstrap'
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
function NavScrollExample() {
  let navigate = useNavigate();
const [auth, setAuth] = useState('');
const [user, setUser] = useState('');

const [modalActive, setModalActive] = useState(false);

const ref = useRef();
 
const ref2 = useRef();
 
 
  const [fix, setFix]=useState(false);
  function setFixed(){
    if (window.scrollY>=200)
    {
      setFix(true)
    }
    else
    {
      setFix(false)
    }
    }
 window.addEventListener("scroll", setFixed)
 /*
 useEffect(()=>{
  Aos.init({duration:2000});
}, []);

const [loading, setLoading] =useState(true)
useEffect(()=>{
setLoading(true)
setTimeout(()=>{
setLoading(false)
}, 2000)
}, [])*/

 



   
  return (
    <div className={fix ? 'navbar_fixed' : 'navbar_all'}>    
        <Navbar className="nb"   variant="dark" expand="lg">   
     <div  data-aos="fade-top"    className='menu'>
 
               <div className={fix ? 'dlogo2' : 'dlogo1'}  > 
                 </div> 
                 <div className={fix ? 'send2' : 'send'}  > 
                 <table><tr><td> <b>Россия, Уфа </b> ул.Шота Руставели, 39/1   </td><td>  <img 
           src={send}
           alt="send"
           width="100%"
           height="100%"
                      /></td></tr>
                         <tr><td>07<sup>00</sup>-15<sup>00</sup>  без выходных</td><td></td></tr></table>
              
                 </div> 
                 
      <ul className=  {fix ? 'menu_ul_2' : 'menu_ul'}   >
        
        <li><span class="underline-one"><NavLink   href="/">Главная</NavLink></span></li>
        <li ><span  >Меню<div className={fix ? 'spico' : 'spico_2'}/></span>
        <ul>
        <li><div className='liline_no'><a href="#hurish">  Бургеры</a></div></li>
          <li><div className='liline_no'><a href="#yakum"> Сэндвичи</a></div></li>
          <li><div className='liline_no'><a href="#duyum">   Хот-дог</a></div></li>
          <li><div className='liline_no'><a href="#desert"> Донер-кебаб</a></div></li>
          <li><div className='liline_no'><a href="#nushoky"> Шаурмы</a></div></li>
                       <li><div className='liline_no'><a href="#nushoky">Боксы</a></div></li>
                       <li><div className='liline_no'><a href="#nushoky"> Картошки</a></div></li>
                      <li><div className='liline_no'><a href="#nushoky"> Напитки</a></div></li>
        </ul>
        </li>
        <li><span class="underline-one" ><NavLink   href="#suroga">Наши контакты</NavLink></span></li>
      
        
      
      </ul>
     <NavLink   onClick={()=>{setModalActive(true)}}  > <img 
                    src={korzina3}
                  
                    className={fix ? 'korzina_2' : 'korzina'} 
                    alt='korzina'
                    />  </NavLink> 

      </div>
                <div class="hamburger-menu">
    <input className='inhide' type="checkbox" id="menu_toggle" />
    <label for="menu_toggle" class="menu_btn"> <span></span>
    </label>
    <div className='menu_box'>
    <div className='menu_box_send'> 
                 
              
                 </div> 
    <ul class="ul_li">    
    <li className='lil'><a href="#hurish">Бургеры</a></li>
          <li className='lil'><a href="#yakum">Сендвичи</a></li>
          <li className='lil'><a href="#duyum">Донер-кебаб</a></li>
          <li className='lil'><a href="#desert">Хот-дог</a></li>
          <li className='lil'><a href="#nushoky">Шаурмы</a></li>
          <li className='lil'><a href="#nushoky">Боксы</a></li>
          <li className='lil'><a href="#nushoky">Еще</a></li>
    </ul>
    
  
    </div>
  </div>
  
     
    <Basket active={modalActive} setActive={setModalActive} >
      <div className='header_basket'>
           <img 
           src={korzina3}
           alt="send"
           width="100%"
           height="100%"
                      />
                      <ul className='ul_header'>
                        <li className='name'>Продукт</li>
                        <li className='price'>Цена</li>
                        <li className='kol'>Количество</li>
                        <li className='priceall'>Общ.цена</li>
                      </ul>
      </div>
      <div className='section_basket' > </div>
 <div className='footer_basket'>
  <button className='button_left'> <a href='/'>Назад</a></button>  <button className='button_right'><a>Заказ по номеру +79273224414  </a> <FaTeamspeak className="icon_right"/></button>
   </div>
      </Basket>
  
    </Navbar>

   
    
                            
        



    

        

             
            

 
 

                 
    </div>






  );
}







export default NavScrollExample;

 

      
  
  
 

 