//import logo from './logo.svg';
import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollButton from './Components/ScrollButton';
import {Asosi} from './Pages/Asosi';
 
 
 
import {Mail} from './Pages/Mail';
 


function App() {
  
 
 

  return (
   <div className='App'>
      {
         <BrowserRouter>
         <ScrollButton />
         <Routes>
     <Route exact path="/" element={<Asosi/>} />
     <Route path="/Mail" element={<Mail/>} />
     </Routes>
    
    
    
    </BrowserRouter>
}
 </div>
  );
}

export default App;
