 
import './yakum.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import gamburger from './../img/gamburger.jpg';
  import chiz from './../img/chiz.jpg';
  import chb from './../img/chb.jpg';
 
  

function Yakum() {
      return (
        <div id="yakum" className="yakum_container">
          
            <div className='yakum'>
              <div className='card_flex'>


<div className='card'>
<div className='card_img'>
<img 
           src={gamburger}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Гамбургер</b> </div>
<div className='card_comment'>котлеты на углях</div>
<div className='card_footer'>
<div className='card_price'>250 ₽ </div>  
<button><span className='vb'>Выбор</span></button>

</div>

</div>



<div className='card'>
<div className='card_img'>
<img 
           src={chiz}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Чизбургер</b> </div>
<div className='card_comment'>с двойными котлетами..</div>
<div className='card_footer'>
<div className='card_price'>300 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>


<div className='card'>
<div className='card_img'>
<img 
           src={chb}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Чикенбургер</b> </div>
<div className='card_comment'>с наггетсами.</div>
<div className='card_footer'>
<div className='card_price'>230 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>





            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Yakum;