
  
import './basket.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
 
 
function Basket({active, setActive, children}) {
 
      return (
        <div className={active ? "modal_active" : "modal_bsk"}  onClick={()=>setActive(false)}>
          <div className={active ? "modal_active_cont" : "modal_cont"} onClick={e=>e.stopPropagation()}>
{children}
          </div>                
        </div>
   )
}

 
export default Basket;