 
import './desert.css';
  
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import hd from './../img/hd.jpg';
  import hdk from './../img/hdk.jpg';
  import bhd from './../img/bhd.png';
function Desert() {
 
      return (
        <div id="desert" className="hurish_container">
          
            <div className='hurish'>
              <div className='card_flex'>



<div className='card'>
<div className='card_img'>
<img 
           src={hd}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Хот-дог с сосиской</b> </div>
<div className='card_comment'>классический</div>
<div className='card_footer'>
<div className='card_price'>150 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>


<div className='card'>
<div className='card_img'>
<img 
           src={hdk}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Хот-дог с курицуй</b> </div>
<div className='card_comment'>мясо, овощи</div>
<div className='card_footer'>
<div className='card_price'>160 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>

<div className='card'>
<div className='card_img'>
<img 
           src={bhd}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Биг хот-дог</b> </div>
<div className='card_comment'>мясо, сосиска</div>
<div className='card_footer'>
<div className='card_price'>180 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>
  

           
            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Desert;