 
import './section.css';
import zal1 from './../img/zal2.jpg';
import zal2 from './../img/zal3.jpg';
import zal3 from './../img/zal.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import { Mail } from '../Pages/Mail';
import { Carousel } from  'react-bootstrap';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
 
  import 'react-circular-progressbar/dist/styles.css';
  import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

  import { ProgressBar } from 'react-bootstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
 
  import axios from 'axios';
  

function Section() {


      return (
        <div className="section">
         <Carousel fade>
      <Carousel.Item>
      <img 
           src={zal1}
           alt="send"
           width="100%"
           height="100%"
                      />
        <Carousel.Caption>
          
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img 
           src={zal2}
           alt="send"
           width="100%"
           height="100%"
                      />
        <Carousel.Caption>
          
           
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img 
           src={zal3}
           alt="send"
           width="100%"
           height="100%"
                      />
        <Carousel.Caption>
      
         
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
           
           
       
        

        </div>
   )
}

 
export default Section;