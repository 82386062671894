 
import './giros.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import girosc from './../img/girosc.jpg';
  import tatmak from './../img/tatmak.jpg';
  import kfree from './../img/kfree.png';
  import naggetsi from './../img/naggetsi.jpeg';
  import kderev from './../img/kderev.jpg';
  import lk from './../img/lk.jpg';
function Giros() {
      return (
        <div id="giros" className="giros_container">
          
            <div className='giros'>
              <div className='card_flex'>


<div className='card'>
<div className='card_img'>
<img 
           src={girosc}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Гирос с курицей</b> </div>
<div className='card_comment'>мясо, картошка фри, овощи</div>
<div className='card_footer'>
<div className='card_price'>250 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>



<div className='card'>
<div className='card_img'>
<img 
           src={tatmak}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Татмак</b> </div>
<div className='card_comment'>мясо, овощи, фирменный соус  </div>
<div className='card_footer'>
<div className='card_price'>200 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>

<div className='card'>
<div className='card_img'>
<img 
           src={kfree}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Картошка фри</b> </div>
<div className='card_comment'>100 грамм </div>
<div className='card_footer'>
<div className='card_price'>110 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>


<div className='card'>
<div className='card_img'>
<img 
           src={naggetsi}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Наггетси</b> </div>
<div className='card_comment'>5 штук </div>
<div className='card_footer'>
<div className='card_price'>125 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>

<div className='card'>
<div className='card_img'>
<img 
           src={kderev}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Картошка по деревенский</b> </div>
<div className='card_comment'>100 грамм </div>
<div className='card_footer'>
<div className='card_price'>120 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>

<div className='card'>
<div className='card_img'>
<img 
           src={lk}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Луковые кольца</b> </div>
<div className='card_comment'>100 грамм </div>
<div className='card_footer'>
<div className='card_price'>140 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>

            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Giros;