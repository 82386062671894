 
import './nushoki.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import limonad from './../img/limonad.jpg';
  import cola from './../img/cola.jpg';
  import fanta from './../img/fanta.jpg';
  import coffee from './../img/coffee.jpg';
  import tea from './../img/tea.jpg';
  import compote from './../img/compote.jpg';
  
function Nushoki() {
       return (
        <div id="nushoky" className="nushoki_container">
          
            <div className='nushoki'>
              <div className='card_flex'>


<div className='card'>
<div className='card_img'>
<img 
           src={limonad}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Лимонад</b> </div>
<div className='card_comment'>сладко газированная вода, 0,5 л.</div>
<div className='card_footer'>
<div className='card_price'>200 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>



<div className='card'>
<div className='card_img'>
<img 
           src={cola}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Cola</b> </div>
<div className='card_comment'>сладко газированная вода, 05 л.</div>
<div className='card_footer'>
<div className='card_price'>250 ₽</div>
<button><span className='vb'>Выбор </span></button>
</div>
</div>

<div className='card'>
<div className='card_img'>
<img 
           src={fanta}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Fanta</b> </div>
<div className='card_comment'>сладко газированная вода, 05 л.</div>
<div className='card_footer'>
<div className='card_price'>250 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>


<div className='card'>
<div className='card_img'>
<img 
           src={compote}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Компот</b> </div>
<div className='card_comment'>0,5 литр.</div>
<div className='card_footer'>
<div className='card_price'>65 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>


<div className='card'>
<div className='card_img'>
<img 
           src={tea}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Стакан чая</b> </div>
<div className='card_comment'>черный / зелёный.</div>
<div className='card_footer'>
<div className='card_price'>30 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>


<div className='card'>
<div className='card_img'>
<img 
           src={coffee}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Кофе</b> </div>
<div className='card_comment'>стакан бумажной кофе.</div>
<div className='card_footer'>
<div className='card_price'>50 ₽</div>
<button><span className='vb'>Выбор</span></button>
</div>
</div>
          
  
          
          
          
            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Nushoki;