 
import './boxi.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import boxk from './../img/boxk.jpg';
  import boxg from './../img/boxg.jpg';
  import mbox from './../img/mbox.jpg';
 
  

function Boxi() {
      return (
        <div id="boxi" className="boxi_container">
          
            <div className='boxi'>
              <div className='card_flex'>


<div className='card'>
<div className='card_img'>
<img 
           src={boxk}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Биг бокс с курицей</b> </div>
<div className='card_comment'>мясо </div>
<div className='card_footer'>
<div className='card_price'>280 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>



<div className='card'>
<div className='card_img'>
<img 
           src={boxg}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Биг бокс с говядиной</b> </div>
<div className='card_comment'>люля кебаб на углях  </div>
<div className='card_footer'>
<div className='card_price'>320 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>

<div className='card'>
<div className='card_img'>
<img 
           src={mbox}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Микс бокс</b> </div>
<div className='card_comment'>куриное мясо + люля кебаб на углях  </div>
<div className='card_footer'>
<div className='card_price'>450 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>








            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Boxi;