 
import './Footer.css';
 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import { Mail } from '../Pages/Mail';
 
import {FaSignInAlt, FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation, FaTelegram} from 'react-icons/fa';
 
  import 'react-circular-progressbar/dist/styles.css';
  import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

  import { ProgressBar } from 'react-bootstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
 
  import axios from 'axios';
 

function Footer() {
 
 


  
 


 

  
 



      return (
        <div id="suroga" className="footer">
          
           
         
    <div class="finish">
        <div class="ff"> 
<div class="f1"> 
 <div class="logotip"><b>ДОНЕР</b><a>БИГ</a></div>

</div>


<div class="f1">
<ul class="menu">
  <li><b>Меню</b></li>
  <li><a href="">Бургеры</a></li>
  <li><a href="">Сэндвичи</a></li>
  <li><a href="">Хот-дог</a></li> 
  <li><a href="">Донер-кебаб</a></li>
  <li><a href="">Боксы</a></li>
  <li><a href="">Шаурмы</a></li>
  <li><a href="">Картошки</a></li>
  <li><a href="">Гирос и татмак</a></li>
  <li><a href="">Напитки</a></li>
</ul>


</div>
<div class="f1">
<ul class="contact">
  <li><b>Контакты</b></li>
  <li>Тел:&ensp;+79273224414 Комилов Илхом</li>
  <li>Адрес:&ensp; Россия, Башкортостан, г.Уфа ул.Шота Руставели, 39/1 	</li>
  <li>  (остановка Адмирала Макарова)</li>
</ul>

</div>
 
 

 



</div>
<div class="social-container">
<a href="http://www.instagram.com/d0nerbig" class="twitter"> Инстаграм<FaInstagram className="icons"/></a>
<a href="#" class="whatsapp"> Telegram<FaTelegram className="icons"/></a>
<a href="#" class="whatsapp"> Whatsapp<FaWhatsapp className="icons"/></a>


</div>
      </div>
     <div class="inline">
     <a className='left'> © 2024. donerbig.ru </a>
     <a className='right'> design and developer <a href="http://www.instagram.com/okil.komilov">okil.komilov</a> </a>
     </div>
  </div> 
            
          
            
              
           
       
        

         
   )
}

 
export default Footer;