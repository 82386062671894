import React, { Component, useEffect } from 'react';
import {useState} from 'react';
import HashLoader from "react-spinners/HashLoader";
import './asosi.css';
import Nav from 'react-bootstrap/Nav';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Section from '../Components/Section';
import Send from '../Components/Send';
import Slider from '../Components/Slider';
import Basket from '../Components/Basket';
 
import Doner from '../Components/Doner';
import Boxi from '../Components/Boxi';
import Shaurma from '../Components/Shaurma';
 import Hurish from '../Components/Hurish';
 import Yakum from '../Components/Yakum';
 import Duyum from '../Components/Duyum';
 import Desert from '../Components/Desert';
 import Nushoki from '../Components/Nushoki';
 import Giros from '../Components/Giros';
 import Aos from 'aos';
 import 'aos/dist/aos.css';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
       FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
        FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
         FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
         FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
          FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
         
export const Asosi=()=>{
      
 


      useEffect(()=>{
            Aos.init({duration:2000});
      }, []);

      const [loading, setLoading] =useState(true)
      useEffect(()=>{
   setLoading(true)
   setTimeout(()=>{
   setLoading(false)
   }, 0)
      }, [])
    

      return(
  /*  loading ?
            <HashLoader
           color="#3d3d3d"
           loading={loading}
           className='spiner'
           size={120}        
                   /> 
      : */ 
            
<div className="section_one">                
  
    <Header />
   <Section />
 
    <Send />
     <Slider />
  
   <Hurish />
     
      <Yakum />
     <Duyum />
     <Doner />
     <Boxi/>
     <Shaurma/>
     <Giros/>
     <Desert />
       <Nushoki /> 
   <Footer />
   
</div>
 
)

      }