import React from "react";
 
import duyum from '../img/duyum.jpg';
import baner1 from '../img/baner1.jpg';
import baner2 from '../img/baner2.jpg';
import baner3 from '../img/baner3.jpg';
import zakladka from '../img/zakladka.png';
import menu from '../img/menu.png';
import { useState } from 'react';
import shef from './../img/shef.png';
import { Carousel } from  'react-bootstrap';

 
import './slider.css';
 function Slider(){
 
    return (
        <div className="slider">
          <div className="sl">
          <div className="cont_slider">
          <div   class="text_slider">
<div class="kak">
 
  <a>Как заказать у нас?</a>
  <br/>
  <p> Вы можете позвонить нам по номеру <span>&ensp; <br/>(+7) 9273224414</span>   и оставить свой заказ  </p>
    
 </div>
    </div>
 <Carousel fade>
      <Carousel.Item>
      <img 
           src={baner1}
           alt="send"
           width="100%"
           height="100%"
                      />
        <Carousel.Caption>
          
          <p>  ...</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img 
           src={baner2}
           alt="send"
           width="100%"
           height="100%"
                      />
        <Carousel.Caption>
          
          <p> ..</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img 
           src={baner3}
           alt="send"
           width="100%"
           height="100%"
                      />
        <Carousel.Caption>
      
          <p>
            ..
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
     
     
    </div>
    </div>
    </div>
    )
}
export default Slider;
