 
import './doner.css';
import './card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
 
 
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaStar, FaCloudscale, FaRadiation} from 'react-icons/fa';
  import skur from './../img/skur.jpg';
  import donerk from './../img/donerk.jpg';
  import doner from './../img/doner.jpeg';
 
  

function Doner() {
      return (
        <div id="doner" className="doner_container">
          
            <div className='doner'>
              <div className='card_flex'>


<div className='card'>
<div className='card_img'>
<img 
           src={doner}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Донер</b> </div>
<div className='card_comment'>с куриным мясом</div>
<div className='card_footer'>
<div className='card_price'>250 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>



<div className='card'>
<div className='card_img'>
<img 
           src={donerk}
            className='cardimg'
           
                      />
</div>
<div className='card_title'><b>Донер-кебаб</b> </div>
<div className='card_comment'>люля на углях, овощи </div>
<div className='card_footer'>
<div className='card_price'>300 ₽</div>
<button><span className='vb'>Выбор</span></button>

</div>

</div>

 








            </div></div>
              
           
       
        

        </div>
   )
}

 
export default Doner;